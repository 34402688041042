import React from 'react'
import style from './style.module.scss'

class ArrowDown extends React.Component {
  render() {

    return(
      <div class={style.arrow}>
        <div class={style.arrow__wrapper}>
          <svg xmlns="http://www.w3.org/2000/svg" width="38" height="18" viewbox="0 0 38 18">
            <path fill="#ffffff" d="M19.208,18.592c-0.241,0-0.483-0.087-0.673-0.261L0.327,1.74c-0.408-0.372-0.438-1.004-0.066-1.413c0.372-0.409,1.004-0.439,1.413-0.066L19.208,16.24L36.743,0.261c0.411-0.372,1.042-0.342,1.413,0.066c0.372,0.408,0.343,1.041-0.065,1.413L19.881,18.332C19.691,18.505,19.449,18.592,19.208,18.592z"></path>
          </svg>
        </div>
      </div>
    )
  }
}

export default ArrowDown
