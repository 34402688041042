import { graphql } from 'gatsby'
import React from 'react'
import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import AniLink from "gatsby-plugin-transition-link/AniLink"
// import img_hero_home from '../../static/img/pages/index-hero.jpg'
import video_hero_home from '../../static/img/video/video-home.mp4'
import img_home_vega from '../../static/img/pages/index-vega-carburanti.png'
import img_home_slider_01 from '../../static/img/pages/index-slider-01.jpg'
import img_home_slider_02 from '../../static/img/pages/index-slider-02.jpg'
import img_home_slider_03 from '../../static/img/pages/index-slider-03.jpg'
import img_home_slider_04 from '../../static/img/pages/index-slider-04.png'
import img_home_oikos from '../../static/img/pages/index-oikos.jpg'
import img_home_bordignon from '../../static/img/pages/index-bordignon.jpg'
import style from '../scss/pages/index.module.scss'
import ArrowDown from 'components/arrowDown'
import Swiper from 'swiper'
import AOS from 'aos'
import TypeIt from 'typeit'

class Index extends React.Component {

  constructor(){
    super()
    this.slider = React.createRef()
    this.prevSlider = React.createRef()
    this.nextSlider = React.createRef()
  }

  componentDidMount(){
    const sliderElem = this.slider.current
    const slider = new Swiper(sliderElem, {
      slidesPerView: 'auto',
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: this.nextSlider.current,
        prevEl: this.prevSlider.current,
      },
    });

    AOS.init({
      duration : 1000
    });

    new TypeIt('.main-title', {
      speed: 150,
    })
      .type('Cust<span>Amazing</span><br /><span> </span>Web')
      .exec(async () => {
        await new Promise((resolve, reject) => {
          //app.goto(1)
          return resolve()
        })
      })
      .go()

  }

  componentDidUpdate(){
    const sliderElem = this.slider.current
    const slider = new Swiper(sliderElem, {
      slidesPerView: 'auto',
      loop: true,
      navigation: {
        nextEl: this.nextSlider.current,
        prevEl: this.prevSlider.current,
      },
    });

  }

  render() {

    const { location, data } = this.props

    return (
      <div className={'page-home'}>
        <Layout location={location}>
          <Meta site={siteMetadata} title="" description={'Dinamiza è la Web agency a Venezia specializzata nello sviluppo di progetti web ad elevato contenuto tecnologico.'}/>

            <div className={style.hero}>

              <div className="row">

                <div className="col-sm-6">
                  <h1 className='main-title'></h1>
                </div>

                <div className="col-sm-6">
                  <div className={style.hero__textSide} data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="3000">
                    <p>Siamo il partner che ti accompagna nella progettazione e sviluppo di prodotti digitali che creino valore per il tuo business. Progettiamo soluzioni tecnologiche e creative, elaborando le vostre idee imprenditoriali.</p>
                    <AniLink to={"/chi-siamo"} className={style.linkArrowGrey} cover direction="up" bg={'#2E2E2F'}>
                      Chi siamo
                    </AniLink>
                  </div>
                </div>

              </div>



              <div className={style.hero__image}>
                {/* <img src={img_hero_home} /> */}
                <video autoPlay muted loop playsInline>
                  <source src={video_hero_home}  type="video/mp4" />
                </video>
                <ArrowDown />
              </div>

              <div className={style.hero__textSideBold} data-aos="fade-up">
                Il nostro è un team che vanta competenze trasversali, spirito di squadra ed esperienza. Copriamo tutte le componenti progettuali con un alto livello di seniority. 
              </div>

              <div className={style.hero__textBottom} data-aos="fade-up">
                Gestiamo diverse tipologie di progetti digitali. Siamo un team di esperti focalizzati sulla parte tecnologica, ma collaboriamo con una rete selezionata di partner specializzati laddove occorre una competenza verticale.
              </div>
              
            </div>

            <div className={style.sliderSection}>
              <div className="row">
                <div className="col-sm-5">
                  <div className={`${style.sliderSection__arrowContainer}`}>
                    <div ref={this.prevSlider} className={`${style.sliderSection__arrowContainer_prev}`}></div>
                    <div ref={this.nextSlider} className={`${style.sliderSection__arrowContainer_next}`}></div>
                  </div>
                </div>
                <div className="col-sm-7">
                  <div ref={this.slider} className="swiper-container">
                    <div className="swiper-wrapper">

                      <div className={`swiper-slide ${style.sliderSection__slide}`}>
                        <div className={`${style.sliderSection__slide__slideContent}`}>
                          <AniLink to={"/servizi"} cover direction="up" bg={'#2E2E2F'}>
                            <img src={img_home_slider_01} />
                            <h5>01. Web e Mobile Application</h5>
                          </AniLink>
                        </div>
                      </div>

                      <div className={`swiper-slide ${style.sliderSection__slide}`}>
                        <div className={`${style.sliderSection__slide__slideContent}`}>
                          <AniLink to={"/servizi"} cover direction="up" bg={'#2E2E2F'}>
                            <img src={img_home_slider_02} />
                            <h5>02. Website</h5>
                          </AniLink>
                        </div>
                      </div>

                      <div className={`swiper-slide ${style.sliderSection__slide}`}>
                        <div className={`${style.sliderSection__slide__slideContent}`}>
                          <AniLink to={"/servizi"} cover direction="up" bg={'#2E2E2F'}>
                            <img src={img_home_slider_03} />
                            <h5>03. Ecommerce</h5>
                          </AniLink>
                        </div>
                      </div>

                      <div className={`swiper-slide ${style.sliderSection__slide}`}>
                        <div className={`${style.sliderSection__slide__slideContent}`}>
                          <AniLink to={"/servizi"} cover direction="up" bg={'#2E2E2F'}>
                            <img src={img_home_slider_04} />
                            <h5>04. Digital Marketing</h5>
                          </AniLink>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              

              <div className={style.sliderSection__title}>
                <div className="row">
                  <h3 data-aos="fade-right">Servizi</h3>
                </div>
              </div>

            </div>



            <div className={style.case}>

              <div className="container-custom">
                <div className="row">
                  <p className={style.case__subtitle} data-aos="fade-right"  >latest Case study</p>
                  <p className={style.case__mainTitle} data-aos="fade-right" data-aos-delay="200" >Vega Carburanti</p>
                  <AniLink to={"/projects/vega"} className={style.linkArrowRed} cover direction="up" bg={'#2E2E2F'} data-aos="fade-right" data-aos-delay="300">
                    Vai alla case
                  </AniLink>
                </div>
              </div>

              <img className={style.case__image} src={img_home_vega} />

              <div className="container-custom">
                <div className="row">
                  <div className="col-md-6">
                    <p className={style.case__titleColumn} data-aos="fade-right">User centered web application</p>
                  </div>
                  <div className="col-md-6">
                    <p className={style.case__textColumn} data-aos="fade-left" data-aos-delay="200" >Piú di cento stazioni di servizio, una piattaforma integrata ai sistemi ERP in uso ben strutturata e prestante arricchita di tutte le funzionalità utili a rendere semplice e unica l'esperienza utente.</p>
                  </div>
                </div>
              </div>

              <div className={style.case__prefooter}>
                <div>
                  <h3 data-aos="fade-down">Dinamiza per il tuo business</h3>
                  <AniLink to={"/contatti"} className={style.linkArrowGrey} cover direction="up" bg={'#2E2E2F'} data-aos="fade-up" data-aos-delay="200">
                    contattaci per una consulenza
                  </AniLink>
                </div>
              </div>

              <div className={style.case__otherProjects}>
                <div className="container-custom">
                  <p className={style.case__subtitle} data-aos="fade-right" >latest Case study</p>
                  <div className="row">
                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="200">
                      <AniLink to={"/projects"} cover direction="up" bg={'#2E2E2F'} >
                        <h3>Oikos</h3>
                        <img src={img_home_oikos} />
                      </AniLink>
                    </div>
                    <div className="col-md-6" data-aos="fade-up" data-aos-delay="400">
                      <AniLink to={"/projects"} cover direction="up" bg={'#2E2E2F'}>
                        <h3>Bordignon</h3>
                        <img src={img_home_bordignon} />
                      </AniLink>
                    </div>
                  </div>
                </div>

                <AniLink to={"/projects"} cover direction="up" bg={'#2E2E2F'} className={`${style.linkArrowRed} ${style.case__otherProjects__linkOtherProjects}`} data-aos="fade-up" data-aos-delay="500">
                  Scopri tutti i progetti
                </AniLink>

              </div>

            </div>

            <div className={style.footer}>
              <div className="container-custom">
                <div className="row">
                  <div className="col-md-6">
                    <h4>Cust<span>Amazing</span><br />Web</h4>
                    <p className={style.footer__address}>Via B. Maderna, 7 | Mestre (VE)<br />info@dinamiza.it<br />TEL. +39 041 8878538</p>
                  </div>
                  <div className="col-md-6">
                    <h4>Let's talk</h4>
                    <AniLink to={"/contatti"} className={style.linkArrowGrey} cover direction="up" bg={'#2E2E2F'}>
                      tell us about your project
                    </AniLink>
                  </div>
                </div>
              </div>
            </div>

        </Layout>
      </div>
    )
  }
}

export default Index
